a {
    transition-duration: .8s;
}


p {
    font-family: "ten-mincho", serif;
}

section {
    margin: 0 auto;
    max-width: 1120px;
}

@include for-mobile {
    section {
        margin: 0 auto 54px;
        min-width: 300px;
    }
}

.c-flex {
    display: flex;
}

.c-text {
    p {
        font-size: 22px;
        line-height: 2.5em;
        margin-bottom: 48px;
    }
}

@include for-mobile {
    .c-text {
        p {
            font-size: 15px;
            letter-spacing: 0;
            line-height: 1.8em;
            margin-bottom: 24px;

            br {
                display: none;
            }
        }
    }
}