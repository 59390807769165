body {
    background-color: $c-m;
    color: rgba(0, 0, 0, .87);
    letter-spacing: .1em;
}

.l-main {
    &__inner {
        padding: 16px;
    }
}

@include for-mobile {
    .l-main {
        &__inner {
            padding-bottom: 0;
        }
    }
}