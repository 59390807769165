.l-footer {
    &__copy {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;

        span {
            display: block;
            font-family: "Roboto", sans-serif;
            font-size: 90%;
            font-weight: 400;
            letter-spacing: 0;
            padding: 16px;
            text-align: center;
        }
    }
}