body {
    &.contact {
        .l-header {
            &__logo {
                a {
                    transform: scale(.5) translateY(-16px);
                }
            }
        }
    }
}

.p-contact {
    margin-bottom: 40px;
    padding-top: 140px;

    &__inner {
        .c-heading {
            margin-top: 0;
            padding-top: 0;
        }

        .form-btn {
            background: #1c1c1c;
            border-radius: 7px;
            color: #fff;
            display: inline-block;
            display: block;
            font-size: 20px;
            font-weight: 700;
            line-height: 1.5em;
            margin: 24px auto 0;
            padding: 14px 20px 10px;
            text-align: center;
            width: 300px;

            &:hover {
                opacity: .8;
                transform: scale(1.1);
                transition-duration: .8s;
            }
        }
    }

    &__form {
        margin: auto;
        margin-bottom: 24px;
        max-width: 700px;
        min-width: 320px;
        text-align: center;

        label {
            display: block;
            margin-bottom: 24px;

            span {
                display: block;
                font-size: 20px;
                margin: 0 auto 8px;
                text-align: left;
                width: 80%;
            }

            input {
                background: $c-m;
                border: 2px solid #1c1c1c;
                border-radius: 10px;
                font-size: 16px;
                outline: none;
                padding: 10px;
                width: 80%;

                &:focus {
                    background: #fff;
                    outline: none;
                }

                &:focus-visible {
                    box-shadow: none;
                }
            }

            textarea {
                background: $c-m;
                border: 2px solid #1c1c1c;
                border-radius: 10px;
                font-size: 16px;
                height: 160px;
                padding: 10px;
                width: 80%;

                &:focus {
                    background: #fff;
                    outline: none;
                }

                &:focus-visible {
                    box-shadow: none;
                }
            }
        }
    }

    &__message {
        p {
            font-family: ten-mincho, serif;
            font-size: 20px;
            margin-bottom: 24px;
            text-align: center;
        }
    }
}

@include for-mobile {
    body {
        &.contact {
            .l-header {
                &__logo {
                    a {
                        transform: translateY(16px);
                    }
                }
            }
        }
    }

    .p-contact {
        padding-top: 140px;

        &__form {
            label {
                span {
                    font-size: 16px;
                    margin: 0 auto 8px;
                    width: 100%;
                }

                input {
                    font-size: 15px;
                    width: 100%;
                }

                textarea {
                    font-size: 15px;
                    height: 160px;
                    width: 100%;
                }
            }
        }

        &__message {
            p {
                font-size: 16px;
                text-align: justify;
            }
        }
    }
}