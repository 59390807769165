@mixin for-mobile {
    @media (max-width: 767px) {
        @content;
    }
}

@mixin for-tablet {
    @media (min-width: 768px) and (max-width: 1140px) {
        @content;
    }
}

@mixin for-desktop {
    @media (min-width: 1920px) {
        @content;
    }
}