.c-heading {
    margin-bottom: 56px;
    margin-top: 40px;
    padding-top: 80px;

    &.center {
        padding-top: 64px;
        text-align: center;
    }

    &.sub {
        margin-bottom: 32px;
        margin-top: 0;
        padding: 0;

        h3 {
            font-size: 28px;
            font-weight: 400;
            letter-spacing: .1em;
        }
    }

    h2 {
        font-family: "ten-mincho", serif;
        font-size: 56px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: .2em;
    }
}

@include for-mobile {
    .c-heading {
        margin-bottom: 24px;
        padding: 48px 0 0;

        h2 {
            font-size: 35px;
            font-weight: 400;
            letter-spacing: .2em;

            span {
                display: block;
                line-height: 1.8;
            }
        }

        &.sub {
            margin-bottom: 16px;

            h3 {
                font-size: 20px;
                font-weight: 400;
                letter-spacing: .1em;
                text-align: center;
            }
        }
    }
}

@include for-tablet {
    .c-heading {
        h2 {
            font-size: 48px;
        }

        &.sub {
            h3 {
                text-align: center;
            }
        }
    }
}

.p-mv {
    padding-top: 92px;
}

@include for-mobile {
    .p-mv {
        padding-top: 48px;

        .slick-slide {
            img {
                aspect-ratio: 1 / .85;
                display: block;
                object-fit: cover;
            }
        }
    }
}

.p-business {
    &__outline {
        &.c-flex {
            align-items: center;
            justify-content: center;
        }

        .business-left {
            text-align: left;
            width: 45%;

            dl {
                dt {
                    font-family: Avenir, lato, noto sans jp, ヒラギノ角ゴ pron, hiragino kaku gothic pron, メイリオ, meiryo, ms p ゴシック, ms pgothic, sans-serif;
                    font-size: 28px;
                    font-weight: 700;
                    letter-spacing: .11em;

                    &:after {
                        background-color: #1c1c1c;
                        border-radius: 10px;
                        content: "";
                        display: block;
                        height: 6px;
                        margin: 16px auto 24px 0;
                        width: 56px;
                    }
                }

                dd {
                    font-family: "ten-mincho", serif;
                    font-size: 16px;
                    line-height: 2em;
                    margin-bottom: 64px;
                    text-align: justify;
                }
            }
        }

        .business-right {
            margin-left: 104px;
            width: 55%;

            img {
                margin-right: calc(50% - 50vw);
                max-inline-size: 110%;
                width: 100vw;
            }
        }
    }
}

@include for-mobile {
    .p-business {
        &__outline {
            &.c-flex {
                flex-wrap: wrap;
            }

            .business-left {
                width: 100%;

                dl {
                    dt {
                        text-align: center;

                        &:after {
                            margin: 16px auto 24px;
                        }
                    }

                    dd {
                        margin-bottom: 40px;
                    }
                }
            }

            .business-right {
                margin-left: 0;
                width: 100%;

                img {
                    margin-right: 0;
                    max-inline-size: 100%;
                    width: 100vw;
                }
            }
        }
    }
}

@include for-tablet {
    .p-business {
        &__outline {
            &.c-flex {
                flex-wrap: wrap;
            }

            .business-left {
                width: 100%;
            }

            .business-right {
                margin-left: 0;
                width: 100%;

                img {
                    margin: 0 auto;
                    max-inline-size: 100%;
                    width: 50vw;
                }
            }
        }
    }
}

.p-company {
    &__wrap {
        margin-bottom: 64px;

        &.c-flex {
            justify-content: space-between;
        }

        .company-left {
            width: 250px;

            img {
                min-width: 250px;
                width: 250px;
            }

            .profile {
                font-family: Avenir, lato, noto sans jp, ヒラギノ角ゴ pron, hiragino kaku gothic pron, メイリオ, meiryo, ms p ゴシック, ms pgothic, sans-serif;
                letter-spacing: .1em;
                margin-top: 16px;
                text-align: center;

                span {
                    display: block;


                    &.role {
                        font-size: 12px;
                        font-weight: 700;
                        margin-bottom: 8px;
                    }

                    &.name {
                        font-size: 24px;
                    }

                    &.ruby {
                        font-size: 14px;
                    }
                }
            }
        }

        .company-right {
            margin-left: 64px;

            p {
                font-size: 15px;
                line-height: 2.2em;
                margin-top: -11px;
                text-align: justify;
            }
        }
    }

    &__info {
        span {
            display: block;
            font-family: Avenir, lato, noto sans jp, ヒラギノ角ゴ pron, hiragino kaku gothic pron, メイリオ, meiryo, ms p ゴシック, ms pgothic, sans-serif;
            font-weight: 700;

            &.company-name {
                font-size: 40px;
                margin-bottom: 8px;
            }

            &.location {
                font-size: 20px;
                margin-bottom: 8px;
            }

            &.mail {
                font-size: 20px;
            }
        }
    }
}

@include for-mobile {
    .p-company {
        &__wrap {
            margin-bottom: 40px;

            &.c-flex {
                flex-wrap: wrap;
            }

            .company-left {
                width: 100%;

                img {
                    margin: 0 auto;
                    text-align: center;
                }

                .profile {
                    margin: 16px auto;

                    span {
                        &.role {
                            font-size: 12px;
                        }

                        &.name {
                            font-size: 20px;
                        }

                        &.ruby {
                            font-size: 10px;
                        }
                    }
                }
            }

            .company-right {
                margin-left: 0;
                width: 100%;

                p {
                    font-size: 15px;
                    letter-spacing: 0;
                    line-height: 2em;
                }
            }
        }

        &__info {
            span {
                text-align: center;

                &.company-name {
                    font-size: 32px;
                }

                &.location {
                    font-size: 18px;
                }
            }
        }
    }
}

@include for-tablet {
    .p-company {
        &__wrap {
            &.c-flex {
                flex-wrap: wrap;
            }

            .company-left {
                width: 100%;

                img {
                    margin: 0 auto;
                    text-align: center;
                }

                .profile {
                    margin: 16px auto;
                }
            }


            .company-right {
                margin-left: 0;
                width: 100%;
            }
        }
    }
}

.p-credit {
    &__inner {
        margin: 0 auto 80px;
        max-width: 1120px;

        p {
            font-family: "Artisan", sans-serif;
            font-size: 90%;
            font-weight: 700;
            letter-spacing: 0;
            margin-bottom: 16px;
            text-align: right;
        }

        .credit-list {
            li {
                font-family: "Roboto", sans-serif;
                font-size: 90%;
                font-style: normal;
                font-weight: 400;
                letter-spacing: 0;
                margin: 0 0 4px;
                text-align: right;
            }
        }
    }
}

@include for-mobile {
    .p-credit {
        &__inner {
            margin: 0 auto 64px;

            p {
                text-align: center;
            }

            .credit-list {
                li {
                    text-align: center;
                }
            }
        }
    }
}

@include for-tablet {
    .p-credit {
        &__inner {
            padding: 0 16px;
            width: 100%;
        }
    }
}