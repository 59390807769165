.l-header {
    background: $c-m;
    height: 108px;
    position: fixed;
    width: 100%;
    z-index: 10;

    &.fixed {
        .l-header {
            &__logo {
                a {
                    transform: scale(0.5) translateY(-16px);
                }
            }

            &__contact {
                .contact-area {
                    a {
                        transform: scale(0.8);

                        &:hover {
                            opacity: 0.5;
                            transform: scale(1);
                            transition-duration: 0.8s;
                        }
                    }
                }
            }
        }
    }

    &__inner {
        padding: 4px 16px;
        position: relative;
        z-index: 0;

        &.c-flex {
            align-items: flex-start;
        }

        .spacer {
            flex-grow: 1;
        }
    }

    &__nav {
        width: 30%;

        .menu-inner {
            .navList {
                padding: 28px;

                li {
                    font-size: 16px;
                    margin: 0 40px 0 0;

                    a {
                        color: #1c1c1c;

                        &:hover {
                            opacity: 0.5;
                        }
                    }
                }
            }
        }
    }

    &__logo {
        a {
            display: inline-block;
            position: relative;
            transform: translateY(32px);
            transition-duration: 0.5s;

            .logo-type {
                left: 0;
                position: relative;
                top: 0;
                z-index: 10;
            }

            .logo-mark {
                left: 180px;
                position: absolute;
                top: 51px;
                z-index: 1;
            }
        }
    }

    &__contact {
        width: 30%;

        .contact-area {
            display: flex;
            justify-content: flex-end;
            margin: 30px 8px;

            img {
                display: none;
            }

            a {
                background: #1c1c1c;
                color: #fff;
                display: inline-block;
                font-size: 1.5rem;
                line-height: 1.5em;
                padding: 6px 14px 2px 16px;

                &:hover {
                    opacity: 0.5;
                    transform: scale(1.2);
                    transition-duration: 0.8s;
                }
            }
        }
    }
}

@include for-mobile {
    .l-header {
        height: 65px;

        &.fixed {
            // height: 75px;

            .l-header {
                &__inner {
                    padding: 16px;
                    position: relative;
                }

                &__logo {
                    a {
                        transform: scale(0.5) translateY(-36px);
                    }
                }

                // &__logo {
                //     height: 80px;
                //     width: 45%;

                //     .service-logo {
                //         margin-left: -5px;
                //         transform: translateY(10px);
                //         transition-duration: 0s;
                //         width: 130px;
                //     }

                //     a {
                //         transform: none;

                //         .logo-mark {
                //             left: 55px;
                //             top: 16px;
                //             width: 27px;
                //         }
                //     }
                // }

                &__contact {
                    .contact-area {
                        display: block;
                        margin: 0;
                        a {
                            align-items: center;
                            aspect-ratio: 1 / 1;
                            border-radius: 50px;
                            display: flex;
                            justify-content: center;
                            padding: 0;
                            width: 48px;
                        }
                        span {
                            display: none;
                        }
                        img {
                            display: block;
                            width: 24px;
                        }
                    }
                }
            }
        }

        &__inner {
            display: flex;
            flex: 1 1 auto;
            flex-direction: column;

            .spacer {
                display: none;
            }
        }

        &__nav {
            display: none;
        }

        &__logo {
            text-align: center;
            width: 100%;

            a {
                transform: translateY(16px);
                // transition-duration: 0s;

                .logo-type {
                    width: 240px;
                }

                .logo-mark {
                    left: 110px;
                    top: 31px;
                    width: 55px;
                }
            }
        }

        &__contact {
            display: flex;
            justify-content: flex-end;
            position: absolute;
            right: 8px;
            top: 6px;
            width: 10%;

            .contact-area {
                align-items: center;
                display: none;
                margin: 13px -13px 0 0;

                a {
                    font-size: 1.2rem;
                }
            }
        }
    }
}

@include for-tablet {
    .l-header {
        &.fixed {
            .l-header {
                &__inner {
                    &.c-flex {
                        flex-direction: inherit;
                    }
                }

                &__contact {
                    .contact-area {
                        display: block;
                    }
                }
            }
        }

        &__inner {
            display: flex;
            flex: 1 1 auto;
            flex-direction: column;

            .spacer {
                display: none;
            }
        }

        &__nav {
            display: none;
        }

        &__logo {
            text-align: center;
            width: 100%;
        }

        &__contact {
            display: none;
        }
    }
}
